<template>
    <div>
        {{ userPays.libelle }}
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: { type: Object, require: true}
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            pays: 'auth/pays'
        }),
        userPays(){
            return this.pays.find(item => item.uid === this.object.pays)
        }
    }
}
</script>